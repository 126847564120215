import { useMemo, useState } from "react";
import { useAutoAnimate } from '@formkit/auto-animate/react'

import { Box, BoxTypes } from '../Box'
import { Paragraph } from '../Typography';

type Props<T> = {
  maxItems?: number;
  items: T[];
  render: (item: T, index: number) => React.ReactElement;
} & BoxTypes

export function Collapsible<T>({ maxItems = 3, items, render, ...props }: Props<T>) {
  const [open, setOpen] = useState(false);
  const [parent] = useAutoAnimate();

  const itemsToShow = useMemo(() => {
    if (open) {
      return items;
    }
    return items.filter((_, i) => i + 1 <= maxItems);
  }, [open]);

  return (
    <Box flexDirection="column" gap="20px" ref={parent} {...props}>
      {itemsToShow.map(render)}
      {items.length > maxItems && (
        <Paragraph onClick={() => setOpen(prev => !prev)} cursor="pointer">
          Show {open ? 'less' : 'more'}
        </Paragraph>
      )}
    </Box>
  )
}
