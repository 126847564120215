import { Step, StepperWrapper } from './styles';

export type StepType = {
  label: string;
  isOptional?: boolean;
};

interface StepperProps {
  /**
   * List of names for each step in order
   */
  steps: Array<StepType>;
  /**
   * The name of the current active step
   */
  activeStep: string;
  /**
   * List of completed steps (based on index numbers of each step)
   */
  completedSteps: number[];
  /**
   * Triggered when the user clicks on a specific step
   */
  onStepClick?: (stepIndex: number) => void;
}

export const Stepper = ({
  steps,
  activeStep,
  completedSteps,
  onStepClick,
}: StepperProps): React.ReactElement => {
  const activeIndex = steps.findIndex((step) => activeStep === step.label);
  return (
    <StepperWrapper data-test="stepper">
      {steps.map((step, index) => {
        const IS_COMPLETED = completedSteps.includes(index);
        const IS_ACTIVE = activeIndex === index;
        return (
          <Step
            key={step.label}
            completed={IS_COMPLETED}
            active={IS_ACTIVE}
            {...(onStepClick && {
              onClick: () => onStepClick(index),
              clickable: true,
            })}
            data-test={IS_ACTIVE ? 'step-item-active' : 'step-item'}
          >
            {step.label}
            {step.isOptional && <span> - Optional</span>}
          </Step>
        );
      })}
    </StepperWrapper>
  );
};
