import * as Sentry from '@sentry/nextjs';
import { useUser } from 'apps/agent-dashboard/contexts/Auth';
import { useFlags } from 'flagsmith/react';

import { useEffect } from 'react';

import { useSession } from 'next-auth/react';

import { config } from '@hl-portals/helpers';

import {
  createContactRequest,
  createVisitorRequest,
  searchContactByFilters,
  updateContactRequest,
} from './requests';

const loadHubSpotWidget = ({ email, token }) => {
  // @ts-ignore
  window.hsConversationsSettings = {
    identificationEmail: email,
    identificationToken: token,
  };
  // @ts-ignore
  if (window.HubSpotConversations?.widget) {
    // @ts-ignore
    window.HubSpotConversations.widget.load();
  }
};

const isTokenExpired = (incomingDateTime): boolean => {
  if (!incomingDateTime) return true;
  const currentDate = new Date().getTime();

  return currentDate - 43200000 > incomingDateTime; // Token expires in 12h
};

const setIsFeatureFlagEnabled = (
  enabled,
  value,
  agentId,
  agentTeamId
): boolean => {
  if (enabled) return true;

  if (!value || (!agentId && !agentTeamId)) return false;

  const isAgentInAllowedList =
    value?.agent_ids?.length && value.agent_ids.includes(Number(agentId));
  if (isAgentInAllowedList) return true;

  const isAgentTeamInAllowedList =
    value?.agent_team_ids?.length &&
    value.agent_team_ids.includes(Number(agentTeamId));
  if (isAgentTeamInAllowedList) return true;

  return false;
};

const createOrUpdateContact = async (user: UserJsonaResponse) => {
  const { success, contacts } = await searchContactByFilters({
    searchData: {
      filterGroups: [
        {
          filters: [
            { propertyName: 'email', value: user?.email, operator: 'EQ' },
          ],
        },
      ],
    },
  });

  if (success && contacts?.results.length) {
    await updateContactRequest({
      user,
      contactId: contacts.results[0].id,
    });
    // if contact was found, it does not matter if the update worked or not to move forward.
    return true;
  } else {
    const { success: createSuccess } = await createContactRequest({
      user,
    });
    return createSuccess;
  }
};

const HubSpotChatVisitor = () => {
  const { user } = useUser();
  const { status: sessionStatus } = useSession();

  const {
    'agent-portal-hubspot-chat': { enabled, value },
  } = useFlags(['agent-portal-hubspot-chat']);
  const flagValues = value ? JSON.parse(value.toString()) : undefined;
  const isFeatureFlagEnabled = setIsFeatureFlagEnabled(
    enabled,
    flagValues,
    user?.agent?.id,
    user?.agent_team?.id
  );

  useEffect(() => {
    if (
      sessionStatus !== 'authenticated' ||
      !user?.email ||
      !config.showHubspotChat ||
      !isFeatureFlagEnabled
    ) {
      return;
    }

    const identifyVisitor = async () => {
      try {
        const hubSpotVisitorToken = localStorage.getItem('hubspotVisitorToken');
        const hsTokenObj = hubSpotVisitorToken
          ? JSON.parse(hubSpotVisitorToken)
          : undefined;

        if (hsTokenObj?.value && !isTokenExpired(hsTokenObj?.createdAt)) {
          loadHubSpotWidget({ email: user?.email, token: hsTokenObj.value });
          return;
        }

        const createContactSuccess = await createOrUpdateContact(user);

        if (createContactSuccess) {
          const { success, token } = await createVisitorRequest({ user });

          if (success && token) {
            localStorage.setItem(
              'hubspotVisitorToken',
              JSON.stringify({ value: token, createdAt: new Date().getTime() })
            );
            loadHubSpotWidget({ email: user?.email, token });
            return;
          }
        }
      } catch (error) {
        const err = `Something wrong has happened during HubSpot Chat setup. Error: ${error}`;
        Sentry.captureException(err);
      }
    };

    identifyVisitor();
  }, [user, sessionStatus]);

  return <></>;
};

export default HubSpotChatVisitor;
