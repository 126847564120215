import { ThemeContext } from 'styled-components';

import React, { useContext } from 'react';

import Link from 'next/link';

import { Logo, LogoWrapper } from './styles';

export interface Props {
  url?: string;
  target?: string;
  isElite?: boolean;
  shouldUseTextLogo?: boolean;
}

const HeaderLogo = ({
  url,
  target = '_self',
  isElite,
  shouldUseTextLogo = false,
}: Props): React.ReactElement => {
  const theme = useContext(ThemeContext) as any;
  const logo = isElite
    ? {
        desktop: shouldUseTextLogo ? '/logo-elite-text.svg' : '/logo-elite.svg',
        mobile: '/logo-elite.svg',
      }
    : {
        desktop: shouldUseTextLogo ? '/logo-text.png' : '/logo.png',
        mobile: '/logo.png',
      };

  return (
    <Link href={url ?? '/'} target={target} passHref={url && url[0] != '/'}>
      <LogoWrapper>
        <picture>
          <source
            media={`(min-width: ${theme.breakpointsValue.md}px)`}
            srcSet={logo.desktop}
          />
          <source
            media={`(min-width: ${theme.breakpointsValue.sm}px)`}
            srcSet={logo.mobile}
          />
          <Logo
            src={logo.desktop}
            isElite={isElite}
            shouldUseTextLogo={shouldUseTextLogo}
            alt="HomeLight logo"
            data-test="header-logo"
          />
        </picture>
      </LogoWrapper>
    </Link>
  );
};

export default HeaderLogo;
