import { ChangeEvent } from 'react';
import { ToggleWrapper, ToggleAnchor, ToggleContainer } from './styles';

export type ToggleProps = {
  onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  'data-test'?: string;
};

export const Toggle = ({
  onChangeHandler,
  checked,
  'data-test': dataTest,
}: ToggleProps): React.ReactElement => (
  <ToggleWrapper data-test={dataTest}>
    <ToggleAnchor
      checked={checked}
      onChange={(e) => {
        onChangeHandler(e);
      }}
    />
    <ToggleContainer />
  </ToggleWrapper>
);
