/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import useSWR from 'swr';

import { UNREAD_MESSAGES_COUNT_PATH } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

const useMessagesUnreadCount = () => {
  const { data, error, mutate } = useSWR(UNREAD_MESSAGES_COUNT_PATH, (url) =>
    enhancedAxios<any>({ url })
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useMessagesUnreadCount;
