import { useEffect } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';

import { Box, BoxTypes } from '../Box';

type YoutubePlayerProps = YouTubeProps & {
  containerProps?: BoxTypes;
  onUnmount?: () => void;
};

export const YouTubePlayer = ({
  containerProps = {},
  onUnmount,
  opts = {},
  style = {},
  ...props
}: YoutubePlayerProps) => {
  useEffect(() => {
    return () => {
      onUnmount && onUnmount();
    };
  }, [onUnmount]);

  return (
    <Box flex="1" {...containerProps}>
      <YouTube
        {...props}
        opts={{ width: '100%', height: '100%', ...opts }}
        style={{ width: '100%', height: '100%', ...style }}
      />
    </Box>
  );
};
