import React from 'react';

import { useRouter } from 'next/router';

import { Paragraph } from '@hl-portals/ui';

import { AlertLink, NewAlertButton } from './styles';

type DynamicBannerProps = {
  onDismiss: () => void;
  agentAgreementChanges: AgentAgreementChangesType;
};

const DynamicBanner = ({
  onDismiss = () => {},
  agentAgreementChanges,
}: DynamicBannerProps): React.ReactElement => {
  const router = useRouter();
  return (
    <>
      <Paragraph variant="text-small" color="red800">
        {agentAgreementChanges.banner_copy}
        <AlertLink
          ml="5px"
          href={agentAgreementChanges.resource_url}
          target="_blank"
        >
          Learn more
        </AlertLink>
      </Paragraph>

      <NewAlertButton
        onClick={() => {
          onDismiss();
          router.replace('/settings/referral-agreement');
        }}
        mt={{ xs: '16px', md: '0px' }}
        width={{ xs: '100%', md: 'auto' }}
        whiteSpace="nowrap"
        size={{ xs: 'medium', md: 'small' }}
      >
        Sign referral agreement
      </NewAlertButton>
    </>
  );
};

export default DynamicBanner;
