import { FilterButtonStyledComponent } from './styles';
import { FilterButtonProps } from './types';

const FilterButton = ({
  children,
  selected,
  className,
  ...rest
}: FilterButtonProps): React.ReactElement => (
  <FilterButtonStyledComponent
    className={className}
    selected={selected}
    {...rest}
  >
    {children}
  </FilterButtonStyledComponent>
);

export default FilterButton;
