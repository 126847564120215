import { Box, Icon, Paragraph } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { Marker, Separator } from './styles';

type StepType = {
  title: string;
  active: boolean;
  completed: boolean;
  onClick?: () => void;
  number: number;
};

export const StepItem = ({
  title,
  active,
  completed,
  onClick,
  number,
}: StepType) => {
  const isFirst = number === 1;

  return (
    <Box
      onClick={onClick}
      alignItems="center"
      width={{ xs: '100%', md: 'auto' }}
      padding={{ xs: '24px 16px', md: '0' }}
      borderTop={{
        xs: isFirst ? 'none' : `1px solid ${theme.colors.coolGray4}`,
        md: 'none',
      }}
    >
      {!isFirst && <Separator display={{ xs: 'none', md: 'block' }} />}
      <Marker active={active} completed={completed} fontWeight="bold">
        {completed ? <Icon type="check" color="white" /> : number}
      </Marker>
      <Paragraph ml="10px" fontWeight={active ? 'bold' : 'normal'}>
        {title}
      </Paragraph>
    </Box>
  );
};

export const StepTracker = ({
  steps,
}: {
  steps: Omit<StepType, 'number'>[];
}) => {
  return (
    <Box
      flexDirection={{ xs: 'column', md: 'row' }}
      width="100%"
      justifyContent="center"
    >
      {steps.map((step, idx) => (
        <StepItem key={step.title} number={idx + 1} {...step} />
      ))}
    </Box>
  );
};
