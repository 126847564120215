/* eslint-disable no-plusplus */
import { IconTypeProp } from '@hl-portals/ui';

import { config } from '@hl-portals/helpers';

type ProgramKeys =
  | 'show_closings'
  | 'show_closings_or_trade_ins'
  | 'show_portal_dashboard'
  | 'show_referrals'
  | 'show_home_loans'
  | 'show_hlss'
  | 'show_metrics'
  | 'show_trade_ins'
  | 'has_team'
  | 'is_agent'
  | 'is_coordinator';

type INavbarItemType = 'link' | 'profile' | 'dropdown' | 'message';

export interface INavbar {
  id: number;
  type: INavbarItemType;
  text?: string;
  url?: string | ((flags?: { enableNewReferrals?: boolean }) => string);
  active?: boolean;
  someProgramKey?: ProgramKeys[];
  everyProgramKey?: ProgramKeys[];
  notActivable?: boolean;
  notRenderMobile?: boolean;
  openInNewTab?: boolean;
  icon?: IconTypeProp;
  hide?: boolean;
  key?: string;
  items?: {
    id: number;
    type: INavbarItemType;
    text?: string;
    url?: string | ((flags?: { enableNewReferrals?: boolean }) => string);
    active?: boolean;
    someProgramKey?: ProgramKeys[];
    everyProgramKey?: ProgramKeys[];
    openInNewTab?: boolean;
    hide?: boolean;
    key?: string;
  }[];
}

export const endabledPrograms = [
  'Trade In',
  'HL Simple Sale',
  'Cash Offer',
  'Closing Services',
];

export const disabledPropgrams = [
  'Fully Funded',
  'Home Loans',
  'Referrals',
  'Cash Close',
];

type TRenderNavBarParams = {
  isMobile: boolean;
};

export function renderNavbar({ isMobile }: TRenderNavBarParams): INavbar[] {
  let uniqueId = 0;

  return [
    {
      id: ++uniqueId,
      type: 'link',
      text: 'Dashboard',
      url: '/dashboard',
      key: 'dashboard',
      everyProgramKey: ['show_portal_dashboard'],
      hide: isMobile,
    },
    {
      id: ++uniqueId,
      type: 'link',
      text: 'Referrals',
      url: `/referrals`,
      key: 'referrals',
      everyProgramKey: ['show_referrals'],
      hide: isMobile,
    },
    {
      id: ++uniqueId,
      type: 'link' as const,
      text: 'Transactions',
      url: '/transactions',
      key: 'transactions',
      everyProgramKey: ['show_closings_or_trade_ins' as const],
      hide: isMobile,
    },
    {
      id: ++uniqueId,
      type: 'message',
      text: 'Messages',
      url: `/messages`,
      key: 'messages',
      notActivable: true,
      hide: isMobile,
    },
    {
      id: ++uniqueId,
      type: 'dropdown',
      text: 'Account',
      key: 'account',
      items: [
        {
          id: ++uniqueId,
          type: 'link',
          text: 'Dashboard',
          url: '/dashboard',
          key: 'dashboard',
          everyProgramKey: ['show_portal_dashboard'],
          hide: !isMobile,
        },
        {
          id: ++uniqueId,
          type: 'link',
          text: 'Referrals',
          url: `/referrals`,
          key: 'referrals',
          everyProgramKey: ['show_referrals'],
          hide: !isMobile,
        },
        {
          id: ++uniqueId,
          type: 'link' as const,
          text: 'Transactions',
          url: '/transactions',
          key: 'transactions',
          everyProgramKey: ['show_closings_or_trade_ins' as const],
          hide: !isMobile,
        },
        {
          id: ++uniqueId,
          type: 'link',
          text: 'Messages',
          url: `/messages`,
          key: 'messages',
          hide: !isMobile,
        },
        {
          id: ++uniqueId,
          type: 'dropdown' as const,
          text: 'Home Search',
          items: [
            {
              id: ++uniqueId,
              type: 'link' as const,
              text: 'Discover homes for sale',
              url: `${config.houseHunterHost}`,
              key: 'discover_homes',
              openInNewTab: true,
            },
            {
              id: ++uniqueId,
              type: 'link' as const,
              text: 'Saved Searches',
              url: `${config.houseHunterHost}/homes/saved-property-searches`,
              key: 'saved_searches',
              openInNewTab: true,
            },
            {
              id: ++uniqueId,
              type: 'link' as const,
              text: 'Recently Viewed',
              url: `${config.houseHunterHost}/homes/recently-viewed`,
              key: 'recently_viewed',
              openInNewTab: true,
            },
            {
              id: ++uniqueId,
              type: 'link' as const,
              text: 'Favorite Homes',
              url: `${config.houseHunterHost}/homes/favorites`,
              key: 'favorite_homes',
              openInNewTab: true,
            },
          ],
        },
        {
          id: ++uniqueId,
          type: 'dropdown' as const,
          text: 'Help and Resources',
          items: [
            {
              id: ++uniqueId,
              type: 'link' as const,
              text: 'Agent Help Center',
              url: 'https://help.homelight.com',
              key: 'agent_help_center',
              openInNewTab: true,
            },
            {
              id: ++uniqueId,
              type: 'link' as const,
              text: 'Client Resource Center',
              url: 'https://www.homelight.com/blog',
              key: 'client_resource_center',
              openInNewTab: true,
            },
            {
              id: ++uniqueId,
              type: 'link' as const,
              text: 'Marketing Resources',
              url: 'https://homelight.com/marketing',
              key: 'marketing_resources',
              openInNewTab: true,
            },
          ],
        },
        {
          id: ++uniqueId,
          type: 'link' as const,
          text: 'Settings',
          url: '/settings',
        },
      ],
    },
  ] as INavbar[];
}
