import { Box, BoxTypes } from '../Box';
import Spinner from '../Spinner';
import { Paragraph } from '../Typography';

type LoadingProgressProps = {
  /**
   * The icon to indicate the loading progress.
   * Default set to the 'Spinner' UI component.
   */
  icon?: React.ReactNode;
  /**
   * The title to appear below icon.
   * Default set to "Loading..."
   */
  title: string;
  /**
   * Optional description to appear below the title.
   */
  description?: string | React.ReactNode;
} & BoxTypes;

const LoadingProgress = ({
  icon,
  title = 'Loading...',
  description,
  ...otherProps
}: LoadingProgressProps): React.ReactElement => (
  <Box
    width={1}
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    data-test="loading-progress"
    {...otherProps}
  >
    {icon || <Spinner xl />}

    <Paragraph variant="heading-4" color="darkBlue" mt="16px">
      {title}
    </Paragraph>

    {description && (
      <Box flexDirection="column" alignItems="center" mt="12px">
        {typeof description === 'string' ? (
          <Paragraph variant="text" color="darkBlue">
            {description}
          </Paragraph>
        ) : (
          description
        )}
      </Box>
    )}
  </Box>
);

export default LoadingProgress;
