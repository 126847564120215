import { SizingProps, SpacingProps } from '@mui/system';

import { ChangeEvent, useMemo, useState } from 'react';

import { Input } from '../Input';

export type HourPickerProps = {
  id?: string;
  value?: string;
  onChange?: (value: string) => void;
} & SpacingProps &
  SizingProps;

export const HourPicker = ({
  id,
  value: defaultValue = '',
  onChange,
  ...restProps
}: HourPickerProps): React.ReactElement => {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState(false);

  const onChangeHour = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    const matchRegex = Boolean(
      newValue.match(/^([0-1])?([0-9])?:?([0-5])?([0-9])? (pm|am)$/)
    );
    const [hour, minute] = newValue
      .split(':')
      .map((str) => str.replace(/[^0-9]+/g, ''));
    const hasError =
      !matchRegex || parseInt(hour, 10) > 12 || parseInt(minute, 10) > 59;
    setError(hasError);
    if (!hasError && newValue.length === 8 && onChange) {
      onChange(newValue);
    }
  };

  const hours = useMemo(
    () =>
      Array(12)
        .fill(0)
        .map((_, index) => index + 1)
        .reduce((acc: string[], val: number) => {
          const newHour = [];
          for (let i = 0; i <= 55; i += 30) {
            const minute = i < 10 ? `0${i}` : `${i}`;
            const hour = val < 10 ? `0${val}` : `${val}`;
            newHour.push(`${hour}:${minute} am`);
            newHour.push(`${hour}:${minute} pm`);
          }
          return acc.concat(newHour);
        }, []),
    []
  );

  return (
    <>
      <Input
        onChange={onChangeHour}
        value={value}
        maxLength={8}
        placeholder="00:00 pm"
        isError={error}
        list="hour-picker"
        id={id}
        {...restProps}
      />

      <datalist id="hour-picker">
        {hours.map((hour) => (
          <option key={hour} value={hour}>
            {hour}
          </option>
        ))}
      </datalist>
    </>
  );
};
