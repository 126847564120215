/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import useSWR from 'swr';

import { searchLeadPath } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

import { useDebounce } from '@hl-portals/hooks';

interface UseSearch {
  leads: any;
  isLoading: boolean;
  isError: boolean;
  mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>;
}

const useSearch = (
  term: string,
  leadFields?: string,
  userTypes?: string
): UseSearch => {
  const debouncedTerm = useDebounce(term, 500);
  const { data, error, mutate } = useSWR(
    () =>
      term.length > 2 && debouncedTerm
        ? searchLeadPath({ term: debouncedTerm, leadFields, userTypes })
        : null,
    (url) => enhancedAxios({ url, translateJsona: true }),
    {
      revalidateOnFocus: false,
    }
  );

  return {
    leads: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useSearch;
