import { useEffect } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { useScreenSize, useUserEvents } from '@hl-portals/hooks';

import { useUser } from '../../../contexts/Auth';
import { INavbar } from '../../../helpers/renderNavbar';
import NavbarDropdown from '../NavbarDropdown';
import NavbarMessage from '../NavbarMessage';
import { NavbarItem, NavbarList, NavbarWrapper } from './styles';

export interface NavbarProps {
  isOpen: boolean;
  isSearchOpen: boolean;
  items: INavbar[];
  isElite?: boolean;
  menuClose?: () => void;
}

const Navbar = ({
  isOpen,
  isSearchOpen,
  items,
  isElite,
  menuClose,
}: NavbarProps): React.ReactElement => {
  const { user, featureFlags } = useUser();
  const router = useRouter();
  const { isMobile } = useScreenSize();
  const { recordUserEvent } = useUserEvents();

  const isItemVisible = (item) =>
    !item?.hide &&
    (typeof item.everyProgramKey === 'undefined' ||
      item?.everyProgramKey?.every?.((program) => featureFlags[program]));

  const checkPrograms = ({
    featureFlags: flags,
    somePrograms,
    everyPrograms,
  }) =>
    (typeof everyPrograms === 'undefined' &&
      typeof somePrograms === 'undefined') ||
    somePrograms?.some((program) => Boolean(flags[program])) ||
    everyPrograms?.every((program) => Boolean(flags[program]));

  const handleOnClickNav = (item: INavbar) => {
    if (item.key && item.type !== 'dropdown') {
      recordUserEvent({
        source: 'agent_dashboard',
        event_action: 'agent_portal_nav_menu',
        result: {
          action: 'click',
          target: item.key,
        },
        includeSource: false,
      });
    }

    menuClose();
  };

  useEffect(() => {
    if (isMobile) {
      const body = document.body;

      body.style.overflowY = isOpen ? 'hidden' : 'scroll';
    }
  }, [isOpen]);

  return (
    <NavbarWrapper isOpen={isOpen} data-test="navbar">
      <NavbarList data-test="navbar-list">
        {items.map((item) => {
          if (item.type === 'dropdown') {
            item?.items?.filter((dropdownItem) =>
              checkPrograms({
                featureFlags,
                somePrograms: dropdownItem.someProgramKey,
                everyPrograms: dropdownItem.everyProgramKey,
              })
            );
          }

          const url = typeof item.url === 'function' ? item.url() : item.url;

          return (
            isItemVisible(item) && (
              <NavbarItem
                key={item.id}
                isElite={isElite}
                active={
                  item.notActivable
                    ? false
                    : window.location.pathname.indexOf(url) === 0
                }
                display={{
                  xs: item.notRenderMobile ? 'none' : 'flex',
                  md: 'flex',
                }}
                isSearchOpen={isSearchOpen}
                data-test="navbar-item"
              >
                {item.type === 'link' && (
                  <Link
                    href={url || ''}
                    target={item.openInNewTab ? '_blank' : '_self'}
                  >
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <a onClick={() => handleOnClickNav(item)}>{item.text}</a>
                  </Link>
                )}

                {item.type === 'message' && (
                  <NavbarMessage
                    item={item}
                    menuClose={menuClose}
                    active={router.pathname === '/messages'}
                    isLight={isElite}
                  />
                )}

                {item.type === 'dropdown' && item.items && (
                  <NavbarDropdown
                    isElite={isElite}
                    item={item}
                    menuClose={menuClose}
                    user={{
                      firstName: user?.first_name,
                      lastName: user?.last_name,
                      email: user?.email,
                    }}
                    alwaysOpen={isMobile && isOpen && item.key === 'account'}
                  />
                )}
              </NavbarItem>
            )
          );
        })}
      </NavbarList>
    </NavbarWrapper>
  );
};

export default Navbar;
