import { useFlags } from 'flagsmith/react';

import React, { useMemo } from 'react';

import { useRouter } from 'next/router';

import { Banner, Box } from '@hl-portals/ui';

import { theme } from '@hl-portals/constants';

import { stringMatchesAny } from '@hl-portals/helpers';

import { useUser } from '../../../contexts/Auth';
import DynamicBanner from './DynamicBanner';
import ReferralsBlockedBanner from './ReferralsBlockedBanner';

type AlertBannerProps = {
  isElite: boolean;
};

const AlertBanner = ({ isElite }: AlertBannerProps): React.ReactElement => {
  const { featureFlags, user } = useUser();
  const router = useRouter();
  const flags = useFlags(['agent_dash_project_cujo']);
  const AlertMap = useMemo(
    () => ({
      'referrals-blocked-banner': {
        dismissed: false,
        Component: ReferralsBlockedBanner,
        onDismiss: undefined,
        type: 'error',
      },
      'dynamic-banner': {
        dismissed: false,
        Component: DynamicBanner,
        onDismiss: undefined,
        type: 'error',
      },
    }),
    []
  );

  const agentAgreementChanges = useMemo(
    () => user.agent?.agent_agreement_changes,
    [user]
  );

  const key = useMemo(() => {
    if (
      !featureFlags.display_agreement_changes &&
      featureFlags.show_referrals_blocked_warning &&
      !stringMatchesAny(
        router.pathname,
        ['/referrals/sign', '/settings/referral-agreement'],
        { strict: false }
      ) &&
      sessionStorage.getItem('hellosign_agent_agreement_signed') !== 'true'
    ) {
      return 'referrals-blocked-banner';
    }

    if (
      flags.agent_dash_project_cujo?.enabled &&
      featureFlags.display_agreement_changes &&
      !stringMatchesAny(
        router.pathname,
        ['/referrals/sign', '/settings/referral-agreement'],
        { strict: false }
      ) &&
      sessionStorage.getItem('hellosign_agent_agreement_signed') !== 'true'
    ) {
      return 'dynamic-banner';
    }
  }, [featureFlags, router.pathname]);

  if (!AlertMap[key]) return null;

  const { dismissed, Component, onDismiss, type } = AlertMap[key];

  if (!Component || dismissed) return null;

  return (
    <Banner
      onDismiss={onDismiss}
      isElite={isElite}
      data-test="alert-banner"
      bgcolor={type === 'error' ? theme.colors.red2 : null}
    >
      <Box
        justifyContent="space-between"
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        width="100%"
      >
        <Component
          isElite={isElite}
          onDismiss={onDismiss}
          agentAgreementChanges={agentAgreementChanges}
        />
      </Box>
    </Banner>
  );
};

export default AlertBanner;
