import { theme } from '@hl-portals/constants';

import { Box, BoxTypes } from '../Box';

type FooterProps = {
  floating?: boolean;
  borderless?: boolean;
  children?: React.ReactNode;
} & BoxTypes;

const Footer = ({
  floating = false,
  children,
  borderTop,
  bgcolor,
  ...extraProps
}: FooterProps): React.ReactElement => (
  <Box
    zIndex={1}
    bgcolor={bgcolor || theme.colors.coolGray6}
    borderTop={borderTop || `1px solid ${theme.colors.coolGray4}`}
    {...(floating && {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
    })}
  >
    <Box
      mx={{ xs: '20px', md: 'auto' }}
      width="1280px"
      my="20px"
      {...extraProps}
    >
      {children}
    </Box>
  </Box>
);

export default Footer;
