import * as Sentry from '@sentry/nextjs';
import flagsmith from 'flagsmith/isomorphic';
import Jsona from 'jsona';
import { TJsonApiBody } from 'jsona/lib/JsonaTypes';
import useSWR from 'swr';
import { MutatorCallback } from 'swr/dist/types';

import React, { createContext, useContext } from 'react';

import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

import { http } from '@hl-portals/libs/http';

import { Spinner } from '@hl-portals/ui';

import {
  EQUITY_APP_PAGES,
  PUBLIC_PAGES,
  TOKENIZED_PAGES,
  USER_CURRENT_PATH,
  spacing,
} from '@hl-portals/constants';

import { config } from '@hl-portals/helpers';

import {
  HeaderContainer,
  HeaderWrapper,
} from '../../components/app/Header/styles';
import HeaderLogo from '../../components/app/HeaderLogo';
import useQueryString from '../../hooks/useQueryString';

export type ContextFeatureFlag = AgentTeamFeatureFlagsType & {
  is_agent: boolean;
  is_elite: boolean;
  is_only_agent: boolean;
  is_coordinator: boolean;
  is_team_leader: boolean;
  show_portal_dashboard: boolean;
  has_team: boolean;
  role: 'agent' | 'coordinator' | 'teamlead' | null;
  should_sign_agreement: boolean;
  display_agreement_changes: boolean;
  show_referrals_blocked_warning: boolean;
  needs_onboarding: boolean;
  needs_email_confirmation: boolean;
  show_ultra_low_rates: boolean;
};

export type UserContextType = {
  user?: UserJsonaResponse;
  isAuthenticated: boolean;
  isLoading: boolean;
  isTokenized: boolean;
  featureFlags: ContextFeatureFlag | null;
  mutate: (
    data?:
      | void
      | UserResponse
      | Promise<void | UserResponse>
      | MutatorCallback<void | UserResponse>,
    shouldRevalidate?: boolean
  ) => Promise<
    | void
    | UserResponse
    | Promise<void | UserResponse>
    | MutatorCallback<void | UserResponse>
  >;
};

const UserContext: React.Context<UserContextType> = createContext({
  user: null,
  isAuthenticated: false,
  isLoading: true,
} as UserContextType);

export interface ProviderProps {
  isEquityApp: boolean;
  children: React.ReactNode;
}

export const UserProvider = ({
  isEquityApp,
  children,
}: ProviderProps): React.ReactElement => {
  const router = useRouter();
  const queryParams = useQueryString();
  const isPublicPage =
    PUBLIC_PAGES.includes(router.pathname) ||
    (config.equityAppEnabled &&
      isEquityApp &&
      EQUITY_APP_PAGES.includes(router.pathname));

  const session = useSession();
  const { status } = session;

  const cacheKey = () => {
    // **** IMPORTANT ****
    // The logic below controls whether the browser executes
    // a request to the "/current" endpoint, if the status is NOT
    // 'authenticated' and the request executes anyway, then the
    // flow will fail and the user will be logged out.
    //
    // Likewise, we don't need to execute the "/current" request
    // when it's a public page, or a tokenized page, since there's
    // either no session, or the details are encoded in the token.
    if (queryParams?.token || isPublicPage || status !== 'authenticated') {
      return null;
    }

    return USER_CURRENT_PATH;
  };

  const { data, error, mutate } = useSWR(cacheKey(), (url) =>
    http.private<UserResponse>({ url })
  );

  const isNotLogged = status !== 'authenticated';
  const isNotAgent = typeof data !== 'undefined' && data?.data === null;
  const isTokenized = typeof queryParams?.token !== 'undefined';
  const isTokenizedPage = TOKENIZED_PAGES.includes(router.pathname);

  const isLoading = !(isTokenized || isTokenizedPage) ? !data && !error : false;

  if (
    ((!isTokenized && !isTokenizedPage && isNotLogged) ||
      (!isTokenized && !isTokenizedPage && isNotAgent) ||
      (isTokenized && !isTokenizedPage)) &&
    !isPublicPage
  ) {
    return (
      <>
        <HeaderWrapper isOpen={false}>
          <HeaderContainer>
            <HeaderLogo />
          </HeaderContainer>
        </HeaderWrapper>
        <Spinner xl m="auto" mt={spacing.m} />
      </>
    );
  }

  const dataFormatter = new Jsona();
  const user = dataFormatter.deserialize(
    (data as TJsonApiBody) ?? {}
  ) as UserJsonaResponse;

  const featureFlags = ((user?.agent
    ? Object.keys(user.agent).reduce((acc, val) => {
        if (val.includes('show_') || val.includes('access_')) {
          return { ...acc, [val]: user.agent[val] };
        }
        return acc;
      }, {})
    : user?.active_agent_team_memberships?.[0]?.agent_team
        ?.agent_team_features) || {}) as UserContextType['featureFlags'];

  if (featureFlags) {
    // injecting pseudo flags
    featureFlags.needs_onboarding =
      Boolean(user) && !Boolean(user?.coordinator) && !Boolean(user?.agent);
    featureFlags.is_agent = Boolean(user?.agent);
    featureFlags.is_elite = Boolean(user?.agent?.elite_agent);
    featureFlags.is_coordinator = Boolean(user?.coordinator);
    featureFlags.has_team = Boolean(user?.agent_team);
    featureFlags.show_metrics = Boolean(user?.agent?.show_metrics);
    featureFlags.show_portal_dashboard = true;
    featureFlags.show_property_questionnaire = true;
    featureFlags.is_team_leader =
      !featureFlags.has_team || user?.agent?.id === user?.agent_team?.agent?.id;
    featureFlags.is_only_agent =
      !featureFlags.is_coordinator &&
      !featureFlags.is_team_leader &&
      featureFlags.is_agent;
    featureFlags.show_closings_or_trade_ins =
      featureFlags?.show_closings ||
      featureFlags?.show_trade_ins ||
      featureFlags?.show_hlss;
    featureFlags.display_agreement_changes =
      user?.agent?.agent_agreement_changes?.display_agreement_changes ?? false;
    featureFlags.show_referrals_blocked_warning =
      user?.agent?.should_sign_agreement &&
      user?.agent?.in_contract &&
      user?.agent?.force_re_sign;
    featureFlags.should_sign_agreement =
      user?.agent?.should_sign_agreement ?? true;
    featureFlags.needs_email_confirmation = !!user && !user?.confirmed_at;
    featureFlags.role = featureFlags.has_team
      ? (featureFlags.is_coordinator && 'coordinator') ||
        (featureFlags.is_only_agent && 'agent') ||
        'teamlead'
      : null;
    featureFlags.show_ultra_low_rates = Boolean(user?.agent?.ultra_subscribed);

    if (user?.coordinator) {
      // Team Coordinator
      featureFlags.show_referrals = true;
      // TC members cannot see the portal dashboard
      featureFlags.show_portal_dashboard = false;
    }
  }

  if (user) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: String(user.id),
      });
      scope.setContext('user', {
        is_agent: featureFlags.is_agent,
        is_elite: featureFlags.is_elite,
        agent_id: parseInt(user?.agent?.id, 10),
        show_closing_services: featureFlags.show_closings,
        show_disclosures: featureFlags.show_disclosuresio,
        show_home_loans: featureFlags.show_home_loans,
        show_metrics: featureFlags.show_metrics,
        show_offers_management: featureFlags.show_offers_management,
        show_property_questionnaire: featureFlags.show_property_questionnaire,
        show_referrals: featureFlags.show_referrals,
        show_trade_ins: featureFlags.show_trade_ins,
        needs_onboarding: featureFlags.needs_onboarding,
      });
    });
  }

  const userContext = {
    isAuthenticated: Boolean(user?.agent) || Boolean(user?.coordinator),
    user: {
      ...user,
      specialties:
        data?.included?.find(
          (v): v is IncludedAgentSpecialties => v.type === 'specialties'
        )?.attributes?.specialties ?? [],
      transactionTeam:
        data?.included?.find(
          (v): v is IncludedTransactionTeam =>
            v?.type === 'team' && v?.attributes?.type === 'TransactionTeam'
        ) ?? null,
    },
    isLoading,
    isTokenized,
    featureFlags,
    mutate,
  };

  if (userContext?.user && userContext?.user?.agent) {
    const { user: userContextUser } = userContext;
    flagsmith.identify(String(user?.id), {
      platform: 'web',
      user_id: userContextUser?.id,
      agent_id: userContextUser?.agent?.id,
      transaction_team_id: userContextUser?.agent_team?.id || null,
      elite_status: userContextUser?.agent?.elite_agent,
      in_contract: userContextUser?.agent?.in_contract,
      agreement_signed_at: userContextUser?.agent?.agreement_signed_at,
      license_number: userContextUser?.agent?.license_number,
      state_code: userContextUser?.agent?.state_code,
      is_team_leader:
        userContextUser?.agent?.id === userContextUser?.agent_team?.agent?.id,
    });
  }

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
};

export const useUser = (): UserContextType => useContext(UserContext);
