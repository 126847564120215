interface GetRangeParams {
  start: number;
  end: number;
}

export const getRange = ({ start, end }: GetRangeParams): number[] =>
  [...Array(end - start + 1)].map((v, i) => i + start);

interface PaginationParams {
  currentPage: number;
  totalPages: number;
}

type Pagination = '...' | number;

export const pagination = ({
  currentPage,
  totalPages,
}: PaginationParams): Pagination[] => {
  let delta: number;
  if (totalPages <= 7) {
    // delta === 7: [1 2 3 4 5 6 7]
    delta = 7;
  } else {
    // delta === 2: [1 ... 4 5 6 ... 10]
    // delta === 4: [1 2 3 4 5 ... 10]
    delta = currentPage > 4 && currentPage < totalPages - 3 ? 2 : 4;
  }

  const range = {
    start: Math.round(currentPage - delta / 2),
    end: Math.round(currentPage + delta / 2),
  };

  if (range.start - 1 === 1 || range.end + 1 === totalPages) {
    range.start += 1;
    range.end += 1;
  }

  let pages: number[] =
    currentPage > delta
      ? getRange({
          start: Math.min(range.start, totalPages - delta),
          end: Math.min(range.end, totalPages),
        })
      : getRange({
          start: 1,
          end: Math.min(totalPages, delta + 1),
        });

  const withDots = (value, pair) =>
    pages.length + 1 !== totalPages ? pair : [value];

  if (pages.length === 0) {
    return [1];
  }

  if (pages[0] !== 1) {
    pages = withDots(1, [1, '...']).concat(pages);
  }

  if (pages[pages.length - 1] < totalPages) {
    pages = pages.concat(withDots(totalPages, ['...', totalPages]));
  }

  return pages;
};
