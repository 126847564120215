import * as Sentry from '@sentry/nextjs';
import flagsmith from 'flagsmith/isomorphic';
import { FlagsmithProvider } from 'flagsmith/react';
import { IState } from 'flagsmith/types';
import { useAuthBoundary } from 'packages/libs/auth/src/hooks';
import {
  ThemeProvider,
  createGlobalStyle,
  css,
  keyframes,
} from 'styled-components';
import { Reset } from 'styled-reset';
import { SWRConfig } from 'swr';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Session } from 'next-auth';
import { getSession } from 'next-auth/react';
import { SessionProvider } from 'next-auth/react';
import NextApp, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { EnvironmentMarker, ToastifyStyle } from '@hl-portals/ui';

import {
  PAGES_TO_HIDE_HEADER,
  PAGES_TO_STYLE_HUBSPOT_CHAT,
  colors,
  theme,
} from '@hl-portals/constants';

import { config } from '@hl-portals/helpers';

import {
  ModalContainer as SharedModalContainer,
  ModalProvider as SharedModalProvider,
  usePageTracking,
} from '@hl-portals/hooks';

import AppMiddleware from '../components/app/AppMiddleware';
import Header from '../components/app/Header';
import HubSpotChatVisitor from '../components/app/HubSpot/HubSpotChatVisitor';
import SignInAlerts from '../components/app/SignInAlerts';
import { UserProvider } from '../contexts/Auth';
import { ModalContainer, ModalProvider } from '../hooks/useModal';
import { SkeletonProvider } from '../hooks/useSkeleton';

Sentry.init({
  enabled: ['production', 'staging'].includes(config.environmentName),
  dsn: config.sentryDsn,
  tracesSampleRate: 0,
  environment: config.environmentName,
});

const skeletonRevealAnimation = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(50%);
  }
`;

const colorClasses = Object.entries(colors)
  .map(
    ([colorName, colorHex]) =>
      `&.--${colorName} {--skeletonBackgroundColor: ${colorHex};}`
  )
  .join('');

export const GlobalStyle = createGlobalStyle<{ url: string }>`
  ${({ url }) =>
    url.includes('/settings') &&
    css`
      html {
        scroll-behavior: smooth;
      }
    `}

  body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;   
    background: ${theme.colors.coolGray6};
  }
  a {
    color: ${theme.colors.electricBlue};
    text-decoration: none;
  }
  #useModal-root {
    position: fixed;
    z-index: 31;
  }

  .--skeleton {
    --skeletonBackgroundColor: #EEF0F6;
    --skeletonSlideDuration: 1s;
    --skeletonAnimationDelay: 0s;
    position: relative;
    overflow: hidden;
    display: inline-block;
    background-color: var(--skeletonBackgroundColor);
    > * {
      opacity: 0;
    }

    ${/* sc-selector */ colorClasses}

    ::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: var(--skeletonBackgroundColor);
    }
    ::after {
      content: '';
      position: absolute;
      width: 200%;
      height: 100%;
      right: 0;
      top: 0;
      background-image: repeating-linear-gradient(to right, transparent 0% 15%, #f4f4f4bf 25%, transparent 35% 50%);
      background-repeat: repeat-x;
      animation: ${skeletonRevealAnimation} var(--skeletonSlideDuration) var(--skeletonAnimationDelay) linear infinite;
    }
  }
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      retry: 0,
      onError: () => {
        toast('There was an error', { type: 'error' });
      },
    },
    mutations: {
      onError: () => {
        toast('There was an error', { type: 'error' });
      },
    },
  },
});

const App = ({
  Component,
  pageProps,
  flagsmithState,
  isEquityApp,
  err,
}: AppProps & {
  err: Error;
  flagsmithState: IState;
  isEquityApp: boolean;
}): React.ReactElement => {
  useAuthBoundary();
  usePageTracking('agent_dashboard');

  const router = useRouter();
  const HIDE_HEADER = PAGES_TO_HIDE_HEADER.includes(router.pathname);
  const SHOW_STAGING_BANNER =
    typeof window !== 'undefined' && window.location.href.includes('staging');

  return (
    <>
      <Head>
        <title>Agent Portal</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 maximum-scale=1.0, user-scalable=no"
        />
        <meta content="#ffffff" name="theme-color" />

        {config.showHubspotChat &&
          PAGES_TO_STYLE_HUBSPOT_CHAT.includes(router.pathname) && (
            <style type="text/css">
              {`
              @media only screen and (max-width: 1399px) {
                body div#hubspot-messages-iframe-container {
                  bottom: 55px !important;
                }
              }
              @media only screen and (max-width: 959px) {
                body div#hubspot-messages-iframe-container {
                  bottom: 70px !important;
                }
              }
            `}
            </style>
          )}
      </Head>
      <Reset />
      <GlobalStyle url={router.pathname} />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        draggable
        closeOnClick
        pauseOnHover
        hideProgressBar
        icon={false}
      />
      {/* @ts-ignore */}
      <FlagsmithProvider flagsmith={flagsmith} serverState={flagsmithState}>
        {/* @ts-ignore */}
        <QueryClientProvider client={queryClient}>
          {/* @ts-ignore */}
          <SWRConfig
            value={{
              revalidateOnFocus: false,
              revalidateOnReconnect: false,
              refreshWhenHidden: false,
              refreshWhenOffline: false,
              refreshInterval: 0,
              dedupingInterval: 0,
              focusThrottleInterval: 0,
              shouldRetryOnError: false,
            }}
          >
            {/* @ts-ignore */}
            <ThemeProvider theme={theme}>
              <ToastifyStyle />
              <SkeletonProvider>
                <SharedModalProvider>
                  <ModalProvider>
                    <UserProvider isEquityApp={isEquityApp}>
                      <AppMiddleware isEquityApp={isEquityApp}>
                        <SignInAlerts />
                        {!HIDE_HEADER && <Header />}
                        {SHOW_STAGING_BANNER && <EnvironmentMarker />}
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <Component {...pageProps} err={err} />
                        <ModalContainer />
                        <SharedModalContainer />
                        <HubSpotChatVisitor />
                      </AppMiddleware>
                    </UserProvider>
                  </ModalProvider>
                </SharedModalProvider>
              </SkeletonProvider>
            </ThemeProvider>
          </SWRConfig>
        </QueryClientProvider>
      </FlagsmithProvider>
    </>
  );
};

const AgentPortal = (
  props: AppProps & { err: Error; flagsmithState: IState; isEquityApp: boolean }
) => {
  return (
    // @ts-ignore
    <SessionProvider session={props?.pageProps?.session}>
      <App
        {...props}
        flagsmithState={props?.flagsmithState}
        isEquityApp={props?.isEquityApp}
      />
    </SessionProvider>
  );
};

AgentPortal.getInitialProps = async (appContext: AppContext) => {
  const { req, query } = appContext.ctx;

  const nextProps = await NextApp.getInitialProps(appContext);

  const session = (await getSession({ req })) as
    | (Session & { userId: string })
    | null;

  await flagsmith.init({
    environmentID: config.flagsmithEnvironmentId,
    identity: session?.userId,
    cacheFlags: false,
  });

  const host = req ? req.headers.host : window.location.hostname;

  const isDevEquityApp =
    query?.equityApp === 'true' &&
    ['development', 'test'].includes(config.environmentName);

  return {
    ...nextProps,
    flagsmithState: flagsmith.getState(),
    isEquityApp: host === config.equityAppUrl || isDevEquityApp,
  };
};

export default AgentPortal;
