import styled from 'styled-components';

export const ImageWrapper = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  padding-top: 3rem;
  img {
    height: 7.5rem;
  }
`;
