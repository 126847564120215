/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState } from 'react';

// conditionally renders component based on given sessionStorage key
//   - requires Component to expect an `onDismiss` fn prop
const useDismissible = (Component, { key, ...props }) => {
  const [dismissed, setDismissed] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDismissed(window.sessionStorage.getItem(key) === 'true');
    }
  }, [key]);

  const onDismiss = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem(key, 'true');
      setDismissed(true);
    }
  }, [key]);

  return {
    key,
    dismissed,
    onDismiss,
    Component: (renderProps) => (
      <Component {...props} {...renderProps} onDismiss={onDismiss} />
    ),
  };
};

export default useDismissible;
