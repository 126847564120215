/* eslint-disable consistent-return */

/* eslint-disable default-case */
import { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';

import { Avatar, Box, Paragraph } from '@hl-portals/ui';

import { capitalizeWords, getInitials } from '@hl-portals/helpers';

import { ResultItemContainer, blueHighlight } from './styles';

interface ResultItemProps {
  isLoading?: boolean;
  searchQuery?: string;
  type?: 'referral' | 'transaction';
  lead?: LeadAttributes;
  preSelected?: boolean;
}

const ResultItem = ({
  isLoading,
  searchQuery,
  type,
  lead,
  preSelected,
}: ResultItemProps): React.ReactElement => {
  const [highlighted, setHighlighted] = useState<boolean>(preSelected || false);

  useEffect(() => {
    setHighlighted(preSelected);
  }, [preSelected]);

  const getServiceIcon = (service: string) => {
    if (
      service === 'cc_trade_in' &&
      lead?.provider_leads &&
      lead?.provider_leads[0].providable?.simple_sale
    ) {
      return '/icons/simple-sale-light.svg';
    }

    const icons = {
      cc_cash_offer: 'cash-offer-light',
      cc_trade_in: 'trade-in-light',
      escrow: 'file-contract-light',
    };
    return `/icons/${icons[service]}.svg`;
  };

  const getReferralLeadType = (userType: string) => {
    if (userType === 'buyer') return 'Buyer';
    return 'Seller';
  };

  return (
    <ResultItemContainer
      width="100%"
      data-test={`navbar-search-${type}-result-item`}
      highlighted={highlighted}
      onMouseOver={() => setHighlighted(true)}
      onMouseLeave={() => setHighlighted(false)}
    >
      <Avatar
        type={type === 'transaction' ? 'image' : 'normal'}
        initials={lead && type === 'referral' ? getInitials(lead.name) : ''}
        src={lead ? getServiceIcon(lead.user_type) : ''}
        surface={type === 'transaction' ? 'dark' : 'light'}
        size="small"
        p="2px"
      />
      <Box flexDirection="column" ml="12px">
        <Box flexDirection="row" mb="4px">
          <Paragraph variant="text-bold" color="darkBlue">
            {isLoading ? (
              <span className="--skeleton">
                Loading skeleton for result title
              </span>
            ) : (
              <Highlighter
                highlightStyle={{ backgroundColor: blueHighlight }}
                searchWords={[searchQuery]}
                autoEscape
                textToHighlight={
                  type === 'referral'
                    ? capitalizeWords(lead.name)
                    : lead.address || 'No address available'
                }
              />
            )}
          </Paragraph>
          {!isLoading && type === 'referral' && (
            <Paragraph variant="text" color="coolGray1" ml="8px">
              {getReferralLeadType(lead.user_type)}
            </Paragraph>
          )}
        </Box>
        <Paragraph variant="text" color="coolGray1">
          {isLoading ? (
            <span className="--skeleton">
              Loading skeleton for result subtitle goes here
            </span>
          ) : (
            <>
              {type === 'transaction' && 'Client: '}
              <Highlighter
                highlightStyle={{ backgroundColor: blueHighlight }}
                searchWords={[searchQuery]}
                autoEscape
                textToHighlight={type === 'referral' ? lead.email : lead.name}
              />
            </>
          )}
        </Paragraph>
      </Box>
    </ResultItemContainer>
  );
};

export default ResultItem;
