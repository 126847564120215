import { SizingProps, SpacingProps } from '@mui/system';

import { Box } from '../Box';
import { Paragraph } from '../Typography';
import { StepCircle, StepContainer, StepContent } from './styles';

type StepProps = {
  title: string;
  optional?: boolean;
  children: React.ReactNode;
} & SpacingProps &
  SizingProps;

export const Step = ({
  children,
  optional,
  title,
  ...restProps
}: StepProps): React.ReactElement => (
  <Box flexDirection="column" width="100%" {...restProps}>
    {children}
  </Box>
);

export const VerticalStepper = ({
  children,
  startIndex = 1,
  contentStyle,
  ...restProps
}: {
  children: Array<React.ReactElement> | React.ReactElement;
  startIndex?: number;
  contentStyle?: Record<string, unknown>;
}): React.ReactElement => {
  const steps = Array.isArray(children) ? children : [children];
  return (
    <Box flexDirection="column" width={1} {...restProps}>
      {steps.filter(Boolean).map((Component, index) => (
        <StepContainer key={Component.props?.title}>
          <Box alignItems="center">
            <StepCircle>{startIndex + index}</StepCircle>
            <Paragraph variant="text-xsmall-bold" color="darkBlue" ml="8px">
              {Component.props?.title}
            </Paragraph>
            {Component.props?.optional && (
              <Paragraph variant="text-xsmall" color="coolGray2" ml="8px">
                Optional
              </Paragraph>
            )}
          </Box>
          <StepContent {...contentStyle}>{Component}</StepContent>
        </StepContainer>
      ))}
    </Box>
  );
};
