import QRCode from 'qrcode';

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const useQRCode = (value: string) => {
  const [qrCode, setQrCode] = useState('');

  useEffect(() => {
    const generateQRCode = () => {
      const styles = { width: 200, height: 200 };

      QRCode.toDataURL(value, styles)
        .then((code) => {
          setQrCode(code);
        })
        .catch((error) => {
          toast(error, { type: 'error' });
        });
    };
    generateQRCode();
  }, [value]);

  return qrCode;
};
