import { Box, Paragraph } from '@hl-portals/ui';

import HeaderLogo from '../HeaderLogo';

export const IdentityChecksLoading = () => (
  <>
    <Box
      width="100%"
      justifyContent="center"
      alignItems="center"
      position="fixed"
      top="0"
      left="0"
      mt="24px"
    >
      <HeaderLogo shouldUseTextLogo />
    </Box>
    <Box
      width="100%"
      height="100vh"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <img src="/illustrations/identity-check-home.png" />
      </Box>
      <Box
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={{ xs: '48px', md: '24px' }}
      >
        <Paragraph variant="heading-2" mt="32px" mb="16px">
          Let’s confirm your information
        </Paragraph>
        <Paragraph variant="text" color="coolGray2">
          It will take you 3 minutes to complete
        </Paragraph>
      </Box>
    </Box>
  </>
);
