import { SizingProps, SpacingProps } from '@mui/system';

import Image from 'next/image';

import { Box } from '../Box';
import { Paragraph } from '../Typography';

interface EmptyStateProps {
  img: {
    src: string;
    width: number;
    height: number;
  };
  heading: string;
  text?: string;
}

const EmptyState = ({
  img,
  heading,
  text,
  ...otherProps
}: EmptyStateProps & SizingProps & SpacingProps): React.ReactElement => (
  <Box
    py="20px"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    data-test="empty-state"
    height={300}
    width={1}
    maxWidth={300}
    {...otherProps}
  >
    <Image src={img.src} width={img.width} height={img.height} loading="lazy" />
    <Paragraph
      variant="heading-5"
      color="coolGray2"
      mt="20px"
      textAlign="center"
    >
      {heading}
    </Paragraph>
    {text && (
      <Paragraph
        variant="text-small"
        color="coolGray2"
        mt="12px"
        textAlign="center"
      >
        {text}
      </Paragraph>
    )}
  </Box>
);

export default EmptyState;
