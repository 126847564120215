import Link from 'next/link';
import { useRouter } from 'next/router';

import { pagination } from '@hl-portals/helpers';

import { useSkeleton } from '@hl-portals/hooks';

import { Box } from '../Box';
import { Icon } from '../Icon';
import PaginationButton from './styles';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  pagePath: string | string[];
  skeletonName?: string;
  onMovePage?: () => void;
  shouldKeepScrollPosition?: boolean;
}

const Pagination = ({
  totalPages,
  currentPage,
  pagePath,
  skeletonName = '',
  onMovePage = () => {},
  shouldKeepScrollPosition,
}: PaginationProps): React.ReactElement => {
  const MINIMUM_PAGE_QUANTITY = totalPages > 0 ? totalPages : 1;
  const { addSkeleton, shouldDisplaySkeleton } = useSkeleton(skeletonName);

  const router = useRouter();
  const pages = pagination({ currentPage, totalPages: MINIMUM_PAGE_QUANTITY });
  const pagesWithKeys = pages.map((page, index) => ({ id: index, page }));

  const redirectToPage = ({ key, href }: { key: string; href: string }) => {
    if (['Enter', ' '].includes(key)) {
      router.push(href);
    }
  };

  const previousPage = currentPage - 1 || 1;
  const nextPage =
    currentPage >= MINIMUM_PAGE_QUANTITY
      ? MINIMUM_PAGE_QUANTITY
      : currentPage + 1;

  const isLastPage = currentPage === MINIMUM_PAGE_QUANTITY;

  return (
    <Box data-test="pagination" alignItems="center">
      {!shouldDisplaySkeleton && (
        <Link
          href={`${pagePath}${previousPage}`}
          passHref
          scroll={!shouldKeepScrollPosition}
        >
          <Icon
            type="arrow"
            size={10}
            mr="12px"
            fill={currentPage === 1 ? 'coolGray3' : 'darkBlue'}
            flip
            data-test="pagination-arrow-previous"
            onClick={onMovePage}
          />
        </Link>
      )}
      {pagesWithKeys.map(({ page, id }) =>
        page !== '...' && !shouldDisplaySkeleton ? (
          <Link
            href={`${pagePath}${page}`}
            key={id}
            passHref
            scroll={!shouldKeepScrollPosition}
          >
            <PaginationButton
              active={currentPage === page}
              isLink
              role="button"
              tabIndex={0}
              onKeyUp={({ key }) => {
                onMovePage();
                redirectToPage({ key, href: `${pagePath}${page}` });
              }}
              onClick={onMovePage}
              data-test={
                currentPage === page
                  ? 'pagination-button-active'
                  : 'pagination-button'
              }
            >
              {page}
            </PaginationButton>
          </Link>
        ) : (
          <PaginationButton
            key={id}
            {...addSkeleton({
              color: 'coolGray4',
            })}
            data-test="pagination-button"
          >
            {page}
          </PaginationButton>
        )
      )}
      {!shouldDisplaySkeleton && (
        <Link
          href={`${pagePath}${nextPage}`}
          passHref
          scroll={!shouldKeepScrollPosition}
        >
          <Icon
            type="arrow"
            size={10}
            ml="12px"
            fill={isLastPage ? 'coolGray3' : 'darkBlue'}
            data-test="pagination-arrow-next"
            onClick={onMovePage}
          />
        </Link>
      )}
    </Box>
  );
};

export default Pagination;
