import { Box, BoxTypes } from '../Box';
import { Icon } from '../Icon';
import { Paragraph } from '../Typography';

type MilestoneProps = {
  text: string | React.ReactNode;
  completed?: boolean;
} & BoxTypes;

const Milestone = ({
  text,
  completed,
  ...otherProps
}: MilestoneProps): React.ReactElement => (
  <Box alignItems="center" {...otherProps}>
    <Box ml="4px">
      <Icon
        type="check"
        color={completed ? 'electricBlue' : 'coolGray4'}
        size={12}
      />
    </Box>
    <Box ml="16px">
      {typeof text === 'string' ? (
        <Paragraph variant="text" color="coolGray1">
          {text}
        </Paragraph>
      ) : (
        text
      )}
    </Box>
  </Box>
);

export default Milestone;
