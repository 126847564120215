import { SpacingProps } from '@mui/system';

import { SwitchItem, SwitchWrapper } from './styles';

interface SwitchProps {
  selectedOption?: string;
  options: Array<{
    text: React.ReactNode;
    value: string;
  }>;
  onChange: (option: string) => void;
  'data-test'?: string;
}

const Switch = ({
  options,
  selectedOption,
  onChange,
  ...extraProps
}: SwitchProps & SpacingProps): React.ReactElement => (
  <SwitchWrapper {...extraProps} data-test="switch">
    {options.map((option) => (
      <SwitchItem
        active={selectedOption === option.value}
        onClick={() => onChange(option.value)}
        key={option.value}
        data-test={
          extraProps['data-test'] ?? selectedOption === option.value
            ? 'switch-item-active'
            : 'switch-item'
        }
      >
        {option.text}
      </SwitchItem>
    ))}
  </SwitchWrapper>
);

export default Switch;
