import {
  ArrowDownIcon,
  DropDownComponent,
  DropdownComponentWrapper,
} from './styles';

type SingleOption<T> = {
  value: T;
  label: string;
};
type Option<T> = Array<
  | SingleOption<T>
  | {
      groupName: string;
      options: Array<SingleOption<T>>;
    }
>;

export type DropdownProps<T extends string = string> = {
  onUnselect?: () => void;
  onSelect: (selected: T) => void;
  selected?: T;
  placeholder?: string;
  options: Option<T>;
};

export function Dropdown<T extends string>({
  onSelect,
  onUnselect,
  options,
  selected,
  placeholder = 'All',
  ...props
}: DropdownProps<T>) {
  return (
    <DropdownComponentWrapper {...props}>
      <DropDownComponent
        value={selected ?? '-1'}
        onChange={(e) => {
          if (e.target.value === '-1' && onUnselect) {
            onUnselect();
          } else {
            onSelect(e.target.value as T);
          }
        }}
      >
        {placeholder && <option value="-1">{placeholder}</option>}
        {options.map((option) =>
          'groupName' in option ? (
            <optgroup label={option.groupName} key={option.groupName}>
              {option.options.map((o) => (
                <option key={o.value} value={o.value}>
                  {o.label}
                </option>
              ))}
            </optgroup>
          ) : (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          )
        )}
      </DropDownComponent>
      <ArrowDownIcon type="solid-down" />
    </DropdownComponentWrapper>
  );
}
