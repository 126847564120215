import styled, { css, keyframes } from 'styled-components';

import { media } from '@hl-portals/helpers';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const slideOutBottom = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(20px);
    opacity: 0;
  }
`;

const slideInBottom = keyframes`
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const CloseButton = styled.div`
  width: var(--closeIconSize);
  padding: var(--padding);
  position: absolute;
  right: 24px;
  top: 12px;
  color: currentColor;
  cursor: pointer;

  &:focus-within {
    outline: none;
    color: ${({ theme }) => theme.colors.electricBlue};
  }
`;

export const ModalStage = styled.div`
  --padding: 1rem;
  --closeIconSize: 14px;
  background-color: #fff;
  padding: var(--padding);
  opacity: 0;
  border-radius: 12px;
  box-shadow: 0px 14px 48px rgba(108, 129, 171, 0.2);

  height: 100%;
  width: 100%;
  box-sizing: border-box;

  ${media.desktop`
  width: auto;
  height: auto;
  align-self: center;
  `}
`;

const OPENING = css`
  display: flex;
  animation: ${fadeIn} 150ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
  z-index: 9;

  > ${ModalStage} {
    animation: ${slideInBottom} 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 150ms
      both;
  }
`;

const OPEN = css`
  display: flex;
  opacity: 1;
  z-index: 9;

  > ${ModalStage} {
    opacity: 1;
    position: relative;
    z-index: 10;
  }
`;

const CLOSING = css`
  display: flex;
  opacity: 1;
  z-index: 9;
  animation: ${fadeOut} 150ms ease-out 250ms both;

  > ${ModalStage} {
    animation: ${slideOutBottom} 250ms cubic-bezier(0.55, 0.085, 0.68, 0.53)
      both;
  }
`;

export const ModalBackdrop = styled.div<{
  currentStatus: 'CLOSED' | 'CLOSING' | 'OPENING' | 'OPEN';
}>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => `${theme.colors.darkBlue}4D`};
  justify-content: center;
  overflow-y: auto;
  align-items: flex-start;

  ${media.tablet`
    backdrop-filter: blur(2px);
  `}

  ${({ currentStatus }) =>
    ({
      OPENING,
      CLOSING,
      OPEN,
      CLOSED: '',
    }[currentStatus])}
`;
