import { EQUITY_CALCULATOR_PATH } from '@hl-portals/constants';

import { enhancedAxios, normalizeCurrency } from '@hl-portals/helpers';

export type CalculateEquityAgentArgs = {
  uuid: string;
  state: string;
  value: string;
  mortgage: string;
  targetAmount: string;
  targetAmountRadio?: string;
  agentCommission: string;
  public?: boolean;
  source_form?: string;
};

export async function calculateAgentEquity(
  {
    uuid,
    state,
    value,
    mortgage,
    targetAmount,
    targetAmountRadio,
    agentCommission,
    source_form,
  }: CalculateEquityAgentArgs,
  signal: AbortSignal,
  eucEnabled?: boolean
) {
  const targetValue =
    targetAmountRadio === '0' && !targetAmount ? '0' : targetAmount;

  const res = await enhancedAxios({
    url: EQUITY_CALCULATOR_PATH,
    params: {
      property_uuid: uuid,
      property_state: state,
      agent_valuation: value && normalizeCurrency(value),
      agent_percentage: eucEnabled ? agentCommission : null,
      target_unlock_amount: targetValue && normalizeCurrency(targetValue),
      agent_estimated_loan_payoff: mortgage && normalizeCurrency(mortgage),
      source_form,
      source: 'agent_euc',
    },
    signal,
  });
  return res?.data?.attributes;
}
