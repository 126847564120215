// @ts-nocheck
import { ButtonWrapper } from './styles';
import { ButtonGroupProps } from './types';

export const ButtonGroup = ({
  orientation = 'horizontal',
  children,
  ...props
}: ButtonGroupProps): React.ReactElement => (
  <ButtonWrapper orientation={orientation} {...props}>
    {children}
  </ButtonWrapper>
);
