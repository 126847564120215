import styled, { css } from 'styled-components';

import { Box } from '@hl-portals/ui';

import { theme as themeConstant } from '@hl-portals/constants';

import { media } from '@hl-portals/helpers';

export const NavbarSearchContainer = styled(Box)<{ isSearchOpen: boolean }>`
  width: 360px;
  margin: 0 24px;

  ${({ isSearchOpen }) =>
    isSearchOpen &&
    css`
      z-index: 40;
      position: absolute;
      top: 8px;
      left: 32px;
      width: calc(100% - 140px);
    `};

  ${media.untilDesktop`
    z-index: 40;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 12px 20px;
    background-color: ${({ theme }) => theme.colors.white};
  `}
`;

export const NavbarSearchOverlayWrapper = styled(Box)`
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const NavbarSearchOverlayContent = styled(Box)`
  width: 100%;
  max-width: 1060px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 1180px) {
    margin: 0 auto;
  }
`;

export const ResultItemContainer = styled(Box)<{ highlighted: boolean }>`
  padding: 12px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.whiteHover};

  ${({ highlighted }) =>
    highlighted &&
    css`
      background-color: ${({ theme }) => theme.colors.electricBlueHover}1A;
    `}
`;

export const blueHighlight = `${themeConstant.colors.electricBlueHover}4D`;
