export const hideSurname = (name: string) => {
  const nameArr = name.split(' ');
  const firstName = nameArr[0] ?? '';
  const surnameFirstLetter = nameArr[1]?.[0] ?? '';

  if (!surnameFirstLetter) {
    return firstName;
  }

  return `${firstName} ${surnameFirstLetter}.`;
};
