import * as Sentry from '@sentry/nextjs';

import { formatPhoneNumber } from '@hl-portals/helpers';

import { SearchContactsDataParams } from './types';

const createContactRequest = ({
  user,
}: {
  user: UserJsonaResponse;
}): Promise<{ success: boolean }> => {
  return new Promise((resolve) => {
    fetch('/api/hubspot/contacts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phone: formatPhoneNumber(user.agent?.phone_mobile),
        agentProfileUrl: `https://sales-app.homelight.com/agents/${user.agent?.id}`,
        isEliteAgent: user.agent?.elite_agent,
      }),
    })
      .then(async (res) => {
        if (res.status === 200) {
          return resolve({ success: true });
        }

        const json = await res.json();

        if (res.status === 409 && json.isExistingContact) {
          return resolve({ success: true });
        }

        throw new Error(
          `Failed to Create Contact in HubSpot. ${
            json.message ? `Error message: ${json.message}` : ''
          }`
        );
      })
      .catch((error) => {
        Sentry.captureException(error);
        return resolve({ success: false });
      });
  });
};

const updateContactRequest = ({
  user,
  contactId,
}: {
  user: UserJsonaResponse;
  contactId: string;
}): Promise<{ success: boolean; isExistingContact?: boolean }> => {
  return new Promise((resolve) => {
    fetch(`/api/hubspot/contacts/${contactId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phone: formatPhoneNumber(user.agent?.phone_mobile),
        agentProfileUrl: `https://sales-app.homelight.com/agents/${user.agent?.id}`,
        isEliteAgent: user.agent?.elite_agent,
      }),
    })
      .then(async (res) => {
        if (res.status === 200) {
          return resolve({ success: true });
        }

        const json = await res.json();

        throw new Error(
          `Failed to Create Contact in HubSpot. ${
            json.message ? `Error message: ${json.message}` : ''
          }`
        );
      })
      .catch((error) => {
        Sentry.captureException(error);
        return resolve({ success: false });
      });
  });
};

const searchContactByFilters = ({
  searchData,
}: {
  searchData: SearchContactsDataParams;
}): Promise<{ contacts?: any; success: boolean }> => {
  return new Promise((resolve) => {
    fetch('/api/hubspot/contacts/search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ searchData }),
    })
      .then(async (res) => {
        const json = await res.json();

        if (res.status === 200) {
          return resolve({ success: true, contacts: json });
        }

        throw new Error(
          `Failed to search contacts by filters from HubSpot API. ${
            json.message ? `Error message: ${json.message}` : ''
          }`
        );
      })
      .catch((error) => {
        Sentry.captureException(error);
        return resolve({ success: false });
      });
  });
};

const createVisitorRequest = ({
  user,
}: {
  user: UserJsonaResponse;
}): Promise<{ success: boolean; token?: string }> => {
  return new Promise((resolve) => {
    fetch('/api/hubspot/visitor', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: user.email,
      }),
    })
      .then(async (res) => {
        const json = await res.json();

        if (json?.token) {
          return resolve({ success: true, token: json.token });
        }

        throw new Error(
          `Failed to obtain Visitor Identification Token from HubSpot API. ${
            json.message ? `Error message: ${json.message}` : ''
          }`
        );
      })
      .catch((error) => {
        Sentry.captureException(error);
        return resolve({ success: false });
      });
  });
};

export {
  createContactRequest,
  createVisitorRequest,
  updateContactRequest,
  searchContactByFilters,
};
