import { useFlags } from 'flagsmith/react';

import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { Spinner } from '@hl-portals/ui';

type FeatureFlagBoundaryProps = {
  flagName: string;
  children: React.ReactNode;
  redirectUrl?: string;
};

const FeatureFlagBoundary = ({
  flagName,
  children,
  redirectUrl = '/dashboard',
}: FeatureFlagBoundaryProps): React.ReactElement => {
  const router = useRouter();

  const {
    [flagName]: { enabled },
  } = useFlags([flagName]);

  useEffect(() => {
    if (!enabled) router.replace(redirectUrl);
  }, [router, enabled, redirectUrl]);

  return enabled ? <>{children}</> : <Spinner xl mt="60px" />;
};

export default FeatureFlagBoundary;
