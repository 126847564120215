import { SpacingProps } from '@mui/system';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { Box } from '../Box';
import { Tab, TabsHead, TabsWrapper } from './styles';

type TabsProps = {
  children: React.ReactNode;
  tabs: string[];
  activeTab: string;
  width?:
    | number
    | string
    | {
        xs?: string | number;
        md?: string | number;
      };
  mobileOnly?: boolean;
} & SpacingProps;

const Tabs = ({
  children,
  tabs,
  activeTab,
  mt,
  mx,
  mb,
  width = 1,
  mobileOnly,
}: TabsProps): React.ReactElement => {
  const router = useRouter();
  return (
    <TabsWrapper
      mb={mb}
      mt={mt}
      mx={mx}
      flexDirection="column"
      width={width}
      mobileOnly={mobileOnly}
      data-test="tabs"
    >
      <TabsHead mobileOnly={mobileOnly} data-test="tabs-head">
        {tabs.map((tab) => (
          <Link
            href={{
              pathname: router.pathname,
              query: { ...router.query, tab },
            }}
            key={tab}
          >
            <Tab
              active={tab === activeTab}
              hasOneTab={tabs.length === 1}
              mobileOnly={mobileOnly}
              data-test="tabs-tab"
            >
              {tab}
            </Tab>
          </Link>
        ))}
      </TabsHead>
      <Box width={1} minHeight="300px" data-test="tabs-content">
        {children}
      </Box>
    </TabsWrapper>
  );
};

export default Tabs;
