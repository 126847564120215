import Script from 'next/script';

const withLottie = (Component: any) => {
  return (props: any) => (
    <>
      <Script src="https://unpkg.com/@lottiefiles/lottie-player@0.4.0/dist/lottie-player.js" />
      <Component {...props} />
    </>
  );
};

export default withLottie;
