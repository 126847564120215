import { ReactNode, SyntheticEvent } from 'react';

import { Box } from '@hl-portals/ui';

import { Accordion, AccordionDetails, AccordionSummary } from './styles';

interface AccordionGroupProps {
  /**
   * Every item in the 'items' array should have:
   * 1. order - The order number
   * 2. title - A summary title
   * 3. content - The content to appear when the accordion item is open
   */
  items: Array<{
    order: number;
    title: ReactNode;
    content: ReactNode;
  }>;
  /**
   * The current accordion item that is open.
   * This will be open by default on the first render.
   */
  activeStep: number;
  /**
   * callback to provide the current active item
   */
  onExpand: (order: number | null) => void;
}

const AccordionGroup = ({
  items,
  activeStep,
  onExpand,
}: AccordionGroupProps): React.ReactElement => {
  const handleChange =
    (panel: number) => (event: SyntheticEvent, newExpanded: boolean) => {
      onExpand(newExpanded ? panel : null);
    };

  return (
    <Box flexDirection="column" data-test="accordion-group">
      {items.map((item, index) => {
        return (
          <Accordion
            key={index}
            expanded={activeStep === item.order}
            onChange={handleChange(item.order)}
            data-test={`accordion-group__step`}
          >
            <AccordionSummary
              aria-controls="application-content"
              data-test="accordion-group__step-title"
            >
              {item.title}
            </AccordionSummary>
            <AccordionDetails data-test="accordion-group__step-content">
              {item.content}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default AccordionGroup;
