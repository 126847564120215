import { colors } from '@homelight/particle-tokens';

import React from 'react';

import { BoxTypes, Icon } from '@hl-portals/ui';

import { BannerContent, BannerWrapper } from './styles';

type BannerProps = {
  children: React.ReactNode;
  onDismiss?: () => void;
  isElite?: boolean;
} & BoxTypes;

const Banner = ({
  children,
  onDismiss,
  isElite = false,
  ...props
}: BannerProps): React.ReactElement => (
  <BannerWrapper
    padding={{ xs: '16px', md: '10px 20px' }}
    backgroundColor={isElite ? '#E9F6FF' : colors.darkBlue}
    {...props}
  >
    <BannerContent
      justifyContent="space-between"
      alignItems={{ xs: 'flex-start', md: 'center' }}
    >
      {children}
      {onDismiss ? (
        <a>
          {' '}
          <Icon
            type="times"
            fill={isElite ? 'darkBlue' : 'white'}
            onClick={onDismiss}
            data-test="dismiss-banner-button"
          />{' '}
        </a>
      ) : null}
    </BannerContent>
  </BannerWrapper>
);

export default Banner;
