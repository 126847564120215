import React, { useContext } from 'react';
import { ModalContext } from './modal.context';
import ModalPortal from './components/modal-portal.component';

const ModalContainer = (): React.ReactElement => {
  const { closeModal, onAnimationEnd, stack } = useContext(ModalContext);

  const DEFAULT_ANIMATION_CLASSES = {
    CLOSED: '--closed',
    OPENING: '--opening',
    OPEN: '--open',
    CLOSING: '--closing',
  };
  const defaultHandler = () => {};

  return (
    <>
      {stack.map((state, index) => {
        const { currentStatus, component, options = {} } = state;

        const defaultModalProps = {
          currentStatus,
          closeModalHandler: closeModal || defaultHandler,
          modalDataTest: options.modalDataTest,
          hideCloseIcon: options.hideCloseIcon,
          enableCloseOnEsc: options.enableCloseOnEsc,
          enableBackDropClick: options.enableBackDropClick,
          animationClassName: DEFAULT_ANIMATION_CLASSES[currentStatus],
          wrapperClassName: options.wrapperClassName,
          enableClose: options.enableClose,
          key: index,
        };

        return {
          CLOSED: null,
          OPENING: (
            <ModalPortal
              {...defaultModalProps}
              onStageAnimationEnd={onAnimationEnd}
            >
              {component}
            </ModalPortal>
          ),
          OPEN: <ModalPortal {...defaultModalProps}>{component}</ModalPortal>,
          CLOSING: (
            <ModalPortal
              {...defaultModalProps}
              onBackdropAnimationEnd={onAnimationEnd}
            >
              {component}
            </ModalPortal>
          ),
        }[currentStatus || 'CLOSED'];
      })}
    </>
  );
};

export default ModalContainer;
