import { useEffect, useState } from 'react';

function useSort(data, fns) {
  const [sortedData, setSortedData] = useState(data);

  useEffect(() => {
    let sorted = [...data];

    fns.forEach((sortFn) => {
      sorted = sorted.sort(sortFn);
    });
    setSortedData(sorted);
  }, [data, fns]);

  return sortedData;
}

export default useSort;
