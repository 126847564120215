import React from 'react';

import { useRouter } from 'next/router';

import { Paragraph, SimpleLink } from '@hl-portals/ui';

import { BENJI_ZENDESK_ARTICLE } from '../../../constants/links';
import { AlertButton } from './styles';

const ReferralsBlockedBanner = ({
  onDismiss = () => {},
}: {
  isElite?: boolean;
  onDismiss: () => void;
  agentAgreementChanges?: AgentAgreementChangesType;
}): React.ReactElement => {
  const router = useRouter();
  return (
    <>
      <Paragraph variant="text-small" color="darkBlue">
        You are no longer able to claim new HomeLight referrals. Review and sign
        our new pricing terms and start connecting with new referrals today.
        <SimpleLink ml="5px" href={BENJI_ZENDESK_ARTICLE} target="_blank">
          Learn more
        </SimpleLink>
      </Paragraph>

      <AlertButton
        onClick={() => {
          onDismiss();
          router.replace('/settings/referral-agreement');
        }}
        isElite={true}
        mt={{ xs: '16px', md: '0px' }}
        width={{ xs: '100%', md: 'auto' }}
        whiteSpace="nowrap"
        size={{ xs: 'medium', md: 'small' }}
      >
        Sign referral agreement
      </AlertButton>
    </>
  );
};

export default ReferralsBlockedBanner;
