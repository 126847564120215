import { useEffect } from 'react';

import { useRouter } from 'next/router';

type UseDomainProps = {
  domain: string;
  enabled?: boolean;
};

export const useDomain = ({ domain, enabled = true }: UseDomainProps) => {
  const router = useRouter();

  useEffect(() => {
    if (enabled && domain && !window.location.origin.includes(domain)) {
      window.location.assign(`${domain}${router.asPath}`);
    }
  }, [domain, router, enabled]);
};

export default useDomain;
