import React from 'react';

import Divider from '.';
import { Box, BoxTypes } from '../Box';
import { Paragraph } from '../Typography';

type DividerWithTextProps = {
  text: string;
} & BoxTypes;

const DividerWithText = ({
  text,
  ...otherProps
}: DividerWithTextProps): React.ReactElement => {
  return (
    <Box margin="24px 0" {...otherProps}>
      <Divider alignSelf="center" marginRight="16px" />
      <Paragraph variant="text" color="coolGray2">
        {text}
      </Paragraph>
      <Divider alignSelf="center" marginLeft="16px" />
    </Box>
  );
};

export default DividerWithText;
