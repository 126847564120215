const useQueryString = (): any => {
  // eslint-disable-next-line no-restricted-globals
  const { search = '' } = typeof location !== 'undefined' ? location : {};
  const searchParams = new URLSearchParams(search);

  return Array.from(searchParams.entries()).reduce(
    (acc, val) => ({ ...acc, [val[0]]: val[1] }),
    {}
  );
};

export default useQueryString;
