interface ErrorBoundaryProps {
  hasError?: boolean;
  children: React.ReactNode;
}

const ErrorBoundary = ({
  hasError = false,
  children,
}: ErrorBoundaryProps): React.ReactElement => {
  if (hasError) {
    return <h1>Something went wrong.</h1>;
  }

  return <>{children}</>;
};

export default ErrorBoundary;
