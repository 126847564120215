/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import Link from 'next/link';

import { Paragraph } from '@hl-portals/ui';

import ResultItem from './ResultItem';

interface ReferralsResultsProps {
  results: LeadAttributes[];
  selectedResult: LeadAttributes;
  onInputClear: () => void;
  searchQuery?: string;
}

const ReferralsResults = ({
  results,
  selectedResult,
  onInputClear,
  searchQuery,
}: ReferralsResultsProps): React.ReactElement => {
  if (results.length > 0) {
    return (
      <>
        {results.map((lead) => (
          <Link key={lead.id} href={`/referrals/page/1?referralId=${lead.id}`}>
            <a onClick={onInputClear}>
              <ResultItem
                searchQuery={searchQuery}
                type="referral"
                lead={lead}
                preSelected={lead.id === selectedResult.id}
              />
            </a>
          </Link>
        ))}
      </>
    );
  }
  return (
    <Paragraph
      variant="text"
      color="coolGray2"
      data-test="navbar-search-no-referrals-results"
    >
      No results for referral leads
    </Paragraph>
  );
};

export default ReferralsResults;
