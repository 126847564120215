/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ThemeContext } from 'styled-components';

import {
  ChangeEvent,
  KeyboardEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useRouter } from 'next/router';

import { Box, EmptyState, Input, Paragraph } from '@hl-portals/ui';

import { useHandleClickOutside } from '@hl-portals/hooks';

import useSearch from '../../../hooks/useSearch';
import ReferralsResults from './ReferralsResults';
import ResultItem from './ResultItem';
import TransactionsResults from './TransactionsResults';
import {
  NavbarSearchContainer,
  NavbarSearchOverlayContent,
  NavbarSearchOverlayWrapper,
} from './styles';

interface NavbarSearchProps {
  isSearchOpen: boolean;
  onSearchToggle: (isOpen: boolean) => void;
}

type LeadResult = LeadAttributes & { resultType: 'referral' | 'transaction' };

const NavbarSearch = ({
  isSearchOpen,
  onSearchToggle,
}: NavbarSearchProps): React.ReactElement => {
  const searchContainerRef = useHandleClickOutside<HTMLDivElement>({
    onClickOutside: () => isSearchOpen && onSearchToggle(false),
  });
  const searchInputRef = useRef<HTMLInputElement>(null);

  const route = useRouter();
  const theme = useContext(ThemeContext);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [referralsResults, setReferralsResults] = useState<LeadResult[]>();
  const [transactionsResults, setTransactionsResults] =
    useState<LeadResult[]>();
  const [selectedResult, setSelectedResult] = useState<LeadResult>();

  const { isLoading, leads } = useSearch(
    searchQuery,
    'name,email,phone,client,stage,user_type,address,provider_leads,order',
    'buyer,seller,cc_trade_in,cc_cash_offer,escrow'
  );

  const showInputClear = useMemo(() => {
    const mediaQuery = `(max-width: ${theme.breakpointsValue.md}px)`;
    const { matches } = window.matchMedia(mediaQuery);
    if (matches) return true;
    return isSearchOpen;
  }, [theme.breakpointsValue.md, isSearchOpen]);

  const resultsSkeleton = useMemo(
    () =>
      Array(5)
        .fill(0)
        .map(() => <ResultItem isLoading />),
    []
  );

  useEffect(() => {
    if (leads && leads.data) {
      const formattedSearchQuery = searchQuery.toLowerCase();

      const referralLeads = leads.data
        .filter((lead) => ['buyer', 'seller'].includes(lead.user_type))
        .map((lead) => ({ ...lead, resultType: 'referral' }))
        .filter(
          (lead) =>
            lead.name.toLowerCase().includes(formattedSearchQuery) ||
            lead.email.toLowerCase().includes(formattedSearchQuery)
        )
        .slice(0, 5);
      setReferralsResults(referralLeads);

      const transactionLeads = leads.data
        .filter((lead) =>
          ['cc_trade_in', 'cc_cash_offer', 'escrow'].includes(lead.user_type)
        )
        .map((lead) => ({ ...lead, resultType: 'transaction' }))
        .filter(
          (lead) =>
            lead?.address?.toLowerCase().includes(formattedSearchQuery) ||
            lead.name.toLowerCase().includes(formattedSearchQuery)
        )
        .slice(0, 5);
      setTransactionsResults(transactionLeads);

      const allResults = referralLeads.concat(transactionLeads);
      if (allResults.length > 0) {
        if (referralLeads.length === 0) {
          setSelectedResult(transactionLeads[0]);
        } else {
          setSelectedResult(allResults[0]);
        }
      }
    } else {
      setReferralsResults(undefined);
      setTransactionsResults(undefined);
    }
  }, [leads, searchQuery]);

  const getTransactionUserRole = (userType) => {
    if (['cc_cash_offer'].includes(userType)) return 'buyer';
    return 'seller';
  };

  const clearResults = () => {
    setReferralsResults(undefined);
    setTransactionsResults(undefined);
    setSelectedResult(undefined);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    clearResults();
  };

  const onInputClear = () => {
    setSearchQuery('');
    onSearchToggle(false);
    clearResults();
  };

  const onKeyDown = (event: KeyboardEvent) => {
    const { key } = event;
    if (key === 'Enter') {
      event.preventDefault();
      if (selectedResult) {
        if (selectedResult.resultType === 'referral') {
          route.push(`/referrals/page/1?referralId=${selectedResult.id}`);
        } else if (selectedResult.resultType === 'transaction') {
          route.push(
            `/transactions/${selectedResult.order.id}/${getTransactionUserRole(
              selectedResult.user_type
            )}`
          );
        }
        onInputClear();
        if (searchInputRef.current) searchInputRef.current.blur();
      }
    } else if (key === 'Tab') {
      onSearchToggle(false);
    } else if (['ArrowUp', 'ArrowDown'].includes(key)) {
      if (selectedResult) {
        const allResults = referralsResults.concat(transactionsResults);
        const selectedIndex = allResults.findIndex(
          (result) => result.id === selectedResult.id
        );
        const maxIndex = allResults.length - 1;
        let newIndex;
        if (key === 'ArrowUp') {
          newIndex = selectedIndex === 0 ? maxIndex : selectedIndex - 1;
        } else {
          newIndex = selectedIndex === maxIndex ? 0 : selectedIndex + 1;
        }
        setSelectedResult(allResults[newIndex]);
      }
    }
  };

  const MINIMUM_SEARCH_CHARACTERS = 3;

  return (
    <NavbarSearchContainer
      ref={searchContainerRef}
      isSearchOpen={isSearchOpen}
      display={{
        xs: isSearchOpen ? 'block' : 'none',
        md: 'block',
      }}
      data-test="navbar-search"
    >
      <Input
        ref={searchInputRef}
        id="navbar-search-input"
        variant="search"
        placeholder="Search people or properties..."
        onChange={onInputChange}
        onFocus={() => onSearchToggle(true)}
        onClear={showInputClear && onInputClear}
        onKeyDown={onKeyDown}
        value={searchQuery}
        data-test="navbar-search-input"
      />
      {isSearchOpen && searchQuery.length >= MINIMUM_SEARCH_CHARACTERS ? (
        <NavbarSearchOverlayWrapper data-test="navbar-search-results-overlay-desktop">
          <NavbarSearchOverlayContent
            ml={{
              xs: '24px',
              md: '60px',
            }}
            p={{
              xs: 0,
              md: '0 20px',
            }}
          >
            <Box width="100%" flexDirection="column">
              <Box flexDirection="column" mt="24px">
                <Paragraph variant="heading-5" color="coolGray1" mb="8px">
                  Referrals
                </Paragraph>
                {referralsResults && !isLoading ? (
                  <ReferralsResults
                    results={referralsResults}
                    selectedResult={selectedResult}
                    onInputClear={onInputClear}
                    searchQuery={searchQuery}
                  />
                ) : (
                  resultsSkeleton
                )}
              </Box>
              <Box flexDirection="column" my="24px">
                <Paragraph variant="heading-5" color="coolGray1" mb="8px">
                  Transactions
                </Paragraph>
                {transactionsResults && !isLoading ? (
                  <TransactionsResults
                    results={transactionsResults}
                    selectedResult={selectedResult}
                    getTransactionUserRole={getTransactionUserRole}
                    onInputClear={onInputClear}
                    searchQuery={searchQuery}
                  />
                ) : (
                  resultsSkeleton
                )}
              </Box>
            </Box>
          </NavbarSearchOverlayContent>
        </NavbarSearchOverlayWrapper>
      ) : (
        <NavbarSearchOverlayWrapper
          display={{ xs: 'flex', md: 'none' }}
          data-test="navbar-search-results-overlay-mobile"
        >
          <NavbarSearchOverlayContent>
            <Box width="100%" justifyContent="center" p="60px 0">
              <EmptyState
                img={{
                  src: '/icons/search.svg',
                  width: 100,
                  height: 100,
                }}
                heading="Start typing to see search results"
                text="You can search by person, address or e-mail"
              />
            </Box>
          </NavbarSearchOverlayContent>
        </NavbarSearchOverlayWrapper>
      )}
    </NavbarSearchContainer>
  );
};

export default NavbarSearch;
