import { PropsWithChildren } from 'react';
import { FieldValues } from 'react-hook-form';

import { MultiStep, MultiStepReturnType } from '../MultiStep';
import { Form as FormComponent, FormProps } from './Form';

type MultiStepFormProps<T extends FieldValues> = {
  multiStepProps: MultiStepReturnType;
} & FormProps<T>;

export function MultiStepForm<T extends FieldValues>({
  methods,
  onSubmit,
  multiStepProps,
  children,
}: PropsWithChildren<MultiStepFormProps<T>>) {
  return (
    <FormComponent<T> methods={methods} onSubmit={onSubmit}>
      <MultiStep.Root {...multiStepProps}>{children}</MultiStep.Root>
    </FormComponent>
  );
}
