import { useQuery } from 'react-query';

import { AGENT_SEARCH_PATH } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

type UseAgentSearchResponse = Array<ObfuscatedAgent>;

const useAgentSearch = ({
  name,
  email,
  licenseNumber,
  licenseState,
}: {
  name: string;
  email: string;
  licenseNumber: string;
  licenseState: string;
}) =>
  useQuery({
    queryKey: `AGENT_SEARCH_${name}_${email}_${licenseNumber}_${licenseState}`,
    queryFn: () =>
      enhancedAxios<UseAgentSearchResponse>({
        url: AGENT_SEARCH_PATH({ name, email, licenseNumber, licenseState }),
        translateJsona: true,
      }),
    enabled:
      [name, email, licenseNumber, licenseState].filter(Boolean).length === 4,
  });

export default useAgentSearch;
