type Obj = {
  [key: string]: any;
};

export function shallowCompareObjects<A extends Obj, B extends Obj>(
  a: A,
  b: B
) {
  return Object.keys(a).every((key) => a[key] === b[key]);
}
