import { useFlags } from 'flagsmith/react';
import { createGlobalStyle } from 'styled-components';

import { useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

import { media, stringMatchesAny } from '@hl-portals/helpers';

import { useUser } from '../../../contexts/Auth';
import useDismissible from '../../../hooks/useDismissible';
import { useModal } from '../../../hooks/useModal';
import DynamicAlert from './DynamicAlert';
import ReferralsBlockedAlert from './ReferralsBlockedAlert';

const SignInAlertsModalStyle = createGlobalStyle`
  .agent-dashboard-session-alert {
    max-width: 512px;
    border-radius: 12px;
    padding: 56px 32px 40px !important;

    ${media.untilDesktop`
      height: auto !important;
      margin: 136px 16px 0px;
      padding: 56px 16px 40px !important;
    `}
  }
`;

const SignInAlerts = (): React.ReactElement => {
  const router = useRouter();
  const { featureFlags, isLoading, user } = useUser();
  const { openModal } = useModal();
  const [opened, setOpened] = useState(false);
  const flags = useFlags(['agent_dash_project_cujo']);
  const [currentAlert, setCurrentAlert] = useState({
    Component: null,
    dismissed: true,
    onDismiss: () => {},
    key: '',
  });

  const agentAgreementChanges = useMemo(
    () => user.agent?.agent_agreement_changes,
    [user]
  );

  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem('dynamic-alert', 'false');
  }
  const agreementStatus = agentAgreementChanges?.agreement_changes_status;

  const DismissibleReferralsBlockedAlert = useDismissible(
    ReferralsBlockedAlert,
    {
      key: 'referrals-blocked-alert',
    }
  );
  const DismissibleDynamicAlert = useDismissible(DynamicAlert, {
    key: 'dynamic-alert',
    agentAgreementChanges: agentAgreementChanges,
    image: agreementStatus === 'restriction' ? '/illustrations/restricted.png' : '/illustrations/agreement.png'
  });

  const AlertMap = useMemo(
    () => ({
      'referrals-blocked-alert': DismissibleReferralsBlockedAlert,
      'dynamic-alert': DismissibleDynamicAlert,
    }),
    [DismissibleReferralsBlockedAlert, DismissibleDynamicAlert]
  );

  useEffect(() => {
    let key: string;
    if (opened) return;

    // magic link redirects are not reflected in next/router here
    const decodedLocation = typeof window !== 'undefined' ?
      location.toString().replace(/%2F/gi, '/') :
      router.pathname

    if (
      !featureFlags.display_agreement_changes &&
      featureFlags.show_referrals_blocked_warning &&
      !stringMatchesAny(
        decodedLocation,
        ['/referrals/sign', '/settings/referral-agreement'],
        { strict: false }
      ) &&
      sessionStorage.getItem('hellosign_agent_agreement_signed') !== 'true'
    ) {
      key = 'referrals-blocked-alert';
    }

    if (
      flags.agent_dash_project_cujo?.enabled &&
      featureFlags.display_agreement_changes &&
      !stringMatchesAny(
        decodedLocation,
        ['/referrals/sign', '/settings/referral-agreement', '/referrals/claim'],
        { strict: false }
      ) &&
      sessionStorage.getItem('hellosign_agent_agreement_signed') !== 'true'
    ) {
      key = 'dynamic-alert';
    }

    if (!key) return;
    const selectedAlert = AlertMap[key];

    if (!selectedAlert) return;

    if (
      selectedAlert.key !== currentAlert.key ||
      selectedAlert.dismissed !== currentAlert.dismissed
    ) {
      setCurrentAlert(selectedAlert);
    }
  }, [router.pathname, opened, featureFlags, AlertMap, currentAlert]);

  useEffect(() => {
    if (isLoading || opened) return;

    const { Component: ModalComponent, dismissed, onDismiss } = currentAlert;

    if (!dismissed && Boolean(ModalComponent)) {
      setOpened(true);

      openModal(<ModalComponent data-test="sign-in-alert-modal" />, {
        wrapperClassName: 'agent-dashboard-session-alert',
        onModalClose: () => {
          onDismiss();
        },
      });
    }
  }, [isLoading, opened, currentAlert, openModal]);

  return <SignInAlertsModalStyle />;
};

export default SignInAlerts;
