import { useFlags } from 'flagsmith/react';

import React from 'react';

import { useRouter } from 'next/router';

import { EQUITY_APP_PAGES } from '@hl-portals/constants';

import { config } from '@hl-portals/helpers';

import { useDomain } from '@hl-portals/hooks';

import { useUser } from '../../../contexts/Auth';

interface AppMiddlewareProps {
  isEquityApp: boolean;
  children: React.ReactNode;
}

const AppMiddleware = ({
  isEquityApp,
  children,
}: AppMiddlewareProps): React.ReactElement => {
  const { user, featureFlags, isLoading } = useUser();
  const router = useRouter();

  const {
    'agent-email-confirmation-interstitial': {
      enabled: emailConfirmationEnabled,
    },
    'bbys-lender-lead-submission-flow': { enabled: equityAppEnabled },
  } = useFlags([
    'agent-email-confirmation-interstitial',
    'bbys-lender-lead-submission-flow',
  ]);

  // only redirect to main app if on main app page w/ equity app url
  useDomain({
    domain: config.appHost,
    enabled:
      equityAppEnabled &&
      config.equityAppEnabled &&
      !EQUITY_APP_PAGES.includes(router.pathname) &&
      isEquityApp,
  });

  const redirectTo = (path: string) => {
    if (!router.pathname.includes(path)) {
      router.replace(path);
      return true;
    }

    return false;
  };

  if (!isLoading && featureFlags.needs_onboarding) {
    if (redirectTo('/agent-onboarding')) return <></>;
  } else if (
    isLoading === false &&
    user?.pending_agent_team_invitations?.length > 0
  ) {
    if (redirectTo('/my-team/requests')) return <></>;
  } else if (
    emailConfirmationEnabled &&
    !isLoading &&
    featureFlags.needs_email_confirmation
  ) {
    if (redirectTo('/confirm-email')) return <></>;
  }

  return <>{children}</>;
};

export default AppMiddleware;
