import styled from 'styled-components';

import { useRouter } from 'next/router';

import { Box, Button, Icon, Paragraph } from '@hl-portals/ui';

import { BENJI_ZENDESK_ARTICLE } from '../../../constants/links';
import { useModal } from '../../../hooks/useModal';

const StyledOutlineButton = styled(Button)`
  ${({ theme }) => `
    &:hover {
      border: 1px solid ${theme.colors.electricBlue};
    }
  `}
`;

const ReferralsBlockedAlert = ({ onDismiss, ...props }) => {
  const router = useRouter();
  const { closeModal } = useModal();

  return (
    <Box {...props} flexDirection="column">
      <Box alignItems="flex-start">
        <Icon type="exclamationCircle" size={32} mr="12px" />
        <Paragraph variant="heading-3">
          You are no longer able to claim new HomeLight referrals
        </Paragraph>
      </Box>

      <Paragraph variant="text" mt="16px">
        Review and sign our new pricing terms and start connecting with new
        referrals today.
      </Paragraph>

      <Button
        size="large"
        mt="32px"
        onClick={() => {
          router.push('/settings/referral-agreement');
          closeModal();
        }}
      >
        Sign referral agreement
      </Button>

      <a
        href={BENJI_ZENDESK_ARTICLE}
        target="_blank"
        onClick={() => {
          closeModal();
        }}
      >
        <StyledOutlineButton
          variant="outlined"
          size="large"
          mt="16px"
          width="100%"
        >
          Learn more
        </StyledOutlineButton>
      </a>
    </Box>
  );
};

export default ReferralsBlockedAlert;
